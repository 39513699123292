import React from 'react'
import "./Spinner.css"


function Spinner() {
    return (
    <div class="sk-folding-cube">
    <div class="sk-cube1 sk-cube"></div>
    <div class="sk-cube2 sk-cube"></div>
    <div class="sk-cube4 sk-cube"></div>
    <div class="sk-cube3 sk-cube"></div>
    </div>
    )
}

export default Spinner
